#bg-vid {
	position: fixed;
	z-index: 1;
	width: 100vw;
	height: 100vh;
}

#bg-vid video {
	object-fit: cover;
	width: 100vw;
	height: 100vh;
}

#bg-vid-1 {
	opacity: 0.8;
}

#verra-content {
	position: relative;
	top: 0;
	z-index: 2;
}

.logo {
	height: 100px;
}

.logo img {
	display: inline-block;
	margin-top: 12px;
	vertical-align: middle;
}

.logo span {
	display: inline-block;
	font: 600 0.8em 'Alumni Sans', sans-serif;
	background: radial-gradient( at top, #ffffff, #494949 100% );
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.hero-content {
	margin: 100px 0 300px 0;
}

.features-tiles {
	padding: 80px 0;
	background-color: #fcfcfc;
}

.features-tiles h2, .features-tiles h4 {
	color: #222222;
}

.features-tiles p {
	color: #666666;
}

#features-split {
	position: relative;
}

/*
#features-split .container {
	margin-top: -100vh;
}
*/

.features-block {
	position: relative;
}

.features-block-content {
	margin-top: -100vh;
	z-index: 2;
}

#bg-vid-2 {
	position: sticky;
	top: 0;
	object-fit: cover;
	width: 100vw;
	height: 100vh;
	z-index: -1;
}

.testimonial {
	background-color: #fcfcfc;
}

.product-item .has-top-divider::before {
	background: #3d3d3d;
}

.product-item-content {
	font-size: 0.9em;
	flex-grow: 1;
	color: #fcfcfc;
}

.product-item-content .price {
	font-size: 1.5em;
}

.product-item-content span {
	display: block;
}

.product-item-content .price .sale {
	color: #ff7ebe;
}

.product-item-content .price .strike {
	text-decoration: line-through;
	color: #989898;
}

.product-item-content .price-disclaimer {
	font-size: 0.8em;
}

.product-item-content ul {
	margin: 20px 0 0 0;
	padding: 20px 0;
	color: #c3c3c3;
}

.product-item-content ul li {
	margin: 0;
	list-style-type: none;
}

.product-item-content ul li.highlight {
	color: #fcfcfc;
	font-weight: bold;
}

.product-item-footer {
	padding: 20px 0 0;
	text-align: center;
}

.product-item button {
	text-transform: uppercase;
}

.product-item .product-item-footer .link {
	margin: 0;
	padding: 0;
	display: inline;
	cursor: pointer;
	background: none;
	border: none;
	font-weight: 400;
	color: #1EDFFD;
	text-transform: none;
}

.product-item .product-item-footer .link:hover {
	color: #fcfcfc;
}

.product-item .product-item-footer .subscribe-secondary {
	margin: 15px 0 0 0;
	display: block;
	font-size: 0.85em;
}

.cta {
	padding-top: 300px;
	padding-bottom: 300px;
	background-color: #fcfcfc;
}

.cta h3 {
	color: #222222;
}

footer {
	font-weight: 400;
}

.subpage-header {
	padding: 100px 0 0;
}

.subpage-body {
	padding: 80px 0;
	background-color: #fcfcfc;
}

.subpage-body h2, .subpage-body h4 {
	color: #222222;
}

.subpage-body h5 {
	color: #444444;
}

.subpage-body p {
	color: #666666;
}

.subpage-body .link-button {
	font-size: 0.8em;
	text-decoration: underline;
	color: #444444;
	border: none;
	background: none;
	cursor: pointer;
}

.subpage-body p a {
	color: #17a8be;
	background: none;
}

.subpage-body p a:hover {
	color: #000000;
}

.contact-body .container {
	max-width: 500px;
	margin: 0 auto;
}

.contact-body .container .section-inner {
	padding: 0 25px;
}

.contact-body p {
	margin: 0 0 20px 0;
}

.contact-body .error {
	color: crimson;
}

.contact-body label {
	display: block;
	margin: 10px 0 2px 0;
	color: #666666;
	font-weight: 400;
	font-size: 0.8em;
}

.contact-body input[type=text], 
.contact-body input[type=email], 
.contact-body input[type=tel], 
.contact-body input[type=password], 
.contact-body textarea {
	display: block;
	width: 100%;
	margin: 0 0 10px 0;
	padding: 4px;
	background: #eaeaea;
	font-size: 0.9em;
	border: none;
}

.contact-body textarea {
	height: 200px;
}

.contact-body input[type=checkbox] {
	-ms-transform: scale(1.5); /* IE */
	-moz-transform: scale(1.5); /* FF */
	-webkit-transform: scale(1.5); /* Safari and Chrome */
	-o-transform: scale(1.5); /* Opera */
	transform: scale(1.5);
}

.contact-body select {
	display: block;
}

.contact-body .checkbox {
	display: flex;
	align-items: center;
	gap: 15px;
}

.contact-body .checkbox input {
	margin: 0 0 0 2px;	
}

.contact-body .checkbox label {
	margin: 0;	
}

.contact-body button {
	margin: 20px 0;
}

.button.loading {
	background-image: url( './assets/images/loading-dots-white.gif' );
	background-size: 100px;
	background-position: center;
	background-repeat: no-repeat;
	color: transparent;
}

.sign-up-form {
	display: flex;
	flex-wrap: nowrap;
	align-items: flex-start;
	position: relative;
	transition: transform 0.25s ease;
}

.sign-up-form .sign-up-section {
	flex-grow: 1;
	min-width: 100%;
	padding: 4px;
}

#terms {
	font-size: 0.8em;
	line-height: 1.3em;
	margin: 10px 0 0 0;
}